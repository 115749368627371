import CTA from "@/components/common/CTA";
import styled from "@/components/contexts/ThemeProvider/styled";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { TreeIcon } from "@gannettdigital/community-hub-components";

interface IProps {
  treePlantingUrl?: string;
  title?: string;
}

export const CTAPlantTree = ({
  treePlantingUrl,
  title = "Plant a tree",
}: IProps) => {
  const { trackEvent } = useAnalytics();

  if (!treePlantingUrl) {
    return null;
  }

  return (
    <Link href={treePlantingUrl} target="_blank">
      <CTA
        renderIcon={() => <TreeIcon fontSize="large" htmlColor="white" />}
        title={title}
        description={<>Give to a forest in need in their&nbsp;memory</>}
        onClick={() =>
          trackEvent({
            label: "trees",
            action: "action bar",
            pageType: DETAIL_PAGE_TYPE,
            category: "navigation",
          })
        }
      />
    </Link>
  );
};

const Link = styled("a")({
  color: "inherit",
  textDecoration: "none",
});
