import AudioPlayer from "@/components/common/AudioPlayer";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

interface IProps {
  obituary: IObituaryFragment;
  title?: React.ReactNode;
}

export const CTAListenStory = ({
  obituary,
  title = "Listen to this story",
}: IProps) => {
  const { trackEvent } = useAnalytics();

  const ttsSrc = `${publicRuntimeConfig.textToSpeechEndpoint}/historical/${obituary.id}`;

  return (
    <AudioPlayer
      src={ttsSrc}
      options={{
        onplay: () =>
          trackEvent({
            action: "action bar",
            label: "audio|play",
            pageType: DETAIL_PAGE_TYPE,
          }),
        onpause: () =>
          trackEvent({
            action: "action bar",
            label: "audio|pause",
            pageType: DETAIL_PAGE_TYPE,
          }),
      }}
      headers={{
        "x-api-key": publicRuntimeConfig.textToSpeechApiKey,
      }}
      title={title}
      description="Hear your loved one's obituary"
    />
  );
};
