import CTA from "@/components/common/CTA";
import theme from "@/components/contexts/ThemeProvider/theme";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import {
  getCurrentURL,
  nativeShare,
  navigatorCanShare,
} from "@/helpers/navigator";
import { getFullName } from "@/helpers/obituary";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import {
  ShareIcon,
  SocialMediaShare,
  Tooltip,
} from "@gannettdigital/community-hub-components";
import { ClickAwayListener } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

interface IProps {
  obituary: IObituaryFragment;
  title?: React.ReactNode;
}

export const CTAShareObituary = ({
  obituary,
  title = "Share obituary",
}: IProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { trackEvent } = useAnalytics();

  const [shareOpen, setShareOpen] = useState(false);

  const socialMediaTitle = `Obituary for ${getFullName(obituary)}`;

  return (
    <ClickAwayListener onClickAway={() => setShareOpen(false)}>
      <div>
        <CTA
          renderIcon={() => <ShareIcon fontSize="large" htmlColor="white" />}
          title={title}
          description="Let your community know"
          onClick={() => {
            if (navigatorCanShare() && isMobile) {
              trackEvent({
                action: "share|native",
                pageType: DETAIL_PAGE_TYPE,
              });
              nativeShare({
                url: getCurrentURL(),
                title: socialMediaTitle,
                text: socialMediaTitle,
                onError: () => {},
              });
            } else {
              setShareOpen(true);
            }
          }}
        >
          <Tooltip
            onClose={() => setShareOpen(false)}
            open={shareOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={
              <div>
                <SocialMediaShare
                  title={socialMediaTitle}
                  onClickConnect={() =>
                    trackEvent({
                      action: "action bar",
                      label: "share|link",
                      pageType: DETAIL_PAGE_TYPE,
                    })
                  }
                  onClickMail={() =>
                    trackEvent({
                      action: "action bar",
                      label: "share|email",
                      pageType: DETAIL_PAGE_TYPE,
                    })
                  }
                  onClickFacebook={() =>
                    trackEvent({
                      action: "action bar",
                      label: "share|facebook",
                      pageType: DETAIL_PAGE_TYPE,
                    })
                  }
                  onClickTwitter={() =>
                    trackEvent({
                      action: "action bar",
                      label: "share|twitter",
                      pageType: DETAIL_PAGE_TYPE,
                    })
                  }
                />
              </div>
            }
          >
            <div></div>
          </Tooltip>
        </CTA>
      </div>
    </ClickAwayListener>
  );
};
