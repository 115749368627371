import CTA from "@/components/common/CTA";
import styled from "@/components/contexts/ThemeProvider/styled";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { RoundedEditIcon } from "@gannettdigital/community-hub-components";

interface IProps {
  obituary: IObituaryFragment;
  hasMemories?: boolean;
  title?: React.ReactNode;
}

export const CTAViewMemoryBoard = ({
  obituary,
  hasMemories,
  title = "View memory board",
}: IProps) => {
  const { trackEvent } = useAnalytics();

  if (!hasMemories) {
    return null;
  }

  return (
    <Link href={`${obituary.slug}/memoryboard`}>
      <CTA
        renderIcon={() => (
          <RoundedEditIcon fontSize="large" htmlColor="white" />
        )}
        title={title}
        description={<>Share a memory, offer a&nbsp;condolence</>}
        onClick={() =>
          trackEvent({
            action: "cta|memoryboard",
            pageType: DETAIL_PAGE_TYPE,
          })
        }
      />
    </Link>
  );
};

const Link = styled("a")({
  color: "inherit",
  textDecoration: "none",
});
